<template>
  <div class="p-grid p-fluid dashboard" v-if="!loading">
    <div class="p-col-12 p-lg-7" />
    <div class="p-col-12 p-lg-2 p-sm-9">
      <div class="card summary season-color">
        <span class="title">Seasons</span>
        <span class="detail">
          <MultiSelect
            v-model="selectedSeasons"
            :options="seasons"
            optionLabel="name"
            placeholder="Select Seasons"
            @change="onSelectSeasonsOrCarts"
          >
            <template #value="seasons">
              {{ getMultiSelectLabel(seasons.value, 'Season') }}
            </template>
            <template #option="season">
              <div class="p-multiselect-season-option">
                <span>
                  {{ season.option.name }}
                  ({{ season.option.start_date | date }} - {{ season.option.end_date | date }})
                </span>
              </div>
            </template>
          </MultiSelect>
        </span>
      </div>
    </div>
    <div class="p-col-12 p-lg-2 p-sm-9">
      <div class="card summary cart-color">
        <span class="title">Carts</span>
        <span class="detail">
          <MultiSelect
            v-model="selectedCarts"
            :options="carts"
            optionLabel="serial_no"
            placeholder="Select Carts"
            @change="onSelectSeasonsOrCarts"
          >
            <template #value="carts">
              {{ getMultiSelectLabel(carts.value, 'Cart') }}
            </template>
            <template #option="cart">
              <div class="p-multiselect-cart-option">
                <span
                  >{{ cart.option.nickname }} ({{
                    cart.option.serial_no
                  }}
                  )</span
                >
              </div>
            </template>
          </MultiSelect>
        </span>
      </div>
    </div>

    <div class="p-col-12 p-lg-1 p-sm-3">
      <div class="card summary toggle-button">
        <span class="weight">
          <SplitButton
            :label="this.selectedDashboardUnit"
            icon="fas fa-fw fa-weight"
            :model="weightSelectOptions"
            @click="toggleUnit($event)"
            class="p-button-info p-weight-toggle-button"
            style="width: 100%; height: 100%"
          />
        </span>
      </div>
    </div>

    <div class="p-col-12 p-lg-4">
      <div class="card summary">
        <span class="title">Fields</span>
        <span class="detail">Number of fields harvested</span>
        <span class="count fields">{{ totalFieldsHarvested }}</span>
      </div>
    </div>
    <div class="p-col-12 p-lg-4">
      <div class="card summary">
        <span class="title">Crops</span>
        <span class="detail">Number of crops harvested</span>
        <span class="count crops">{{ totalCropsHarvested }}</span>
      </div>
    </div>
    <div class="p-col-12 p-lg-4">
      <div class="card summary">
        <span class="title">Unloads</span>
        <span class="detail">Total unload weight for Season</span>
        <span class="count unloads"
          >{{ totalUnloaded | selectedDashboardUnitFilter }}
          {{ selectedDashboardUnit }}
        </span>
      </div>
    </div>

    <div class="p-col-12 p-xl-6">
      <div class="card summary">
        <span class="title">Bin Totals</span>
        <div v-if="unloadsPerBin" class="center">
          <Chart :options="chartOptionsBar" type="bar" :data="unloadsPerBin" style="width: 100%; max-width: 600px;" />
        </div>
        <span v-else class="detail">No bin data available</span>
      </div>
    </div>

    <div class="p-col-12 p-xl-6">
      <div class="card summary">
        <span class="title">Crop Totals</span>
        <div v-if="unloadsPerCrop" class="center">
          <Chart :options="chartOptionsBar"
            type="bar"
            :data="unloadsPerCrop"
            ref="cropTotals"
            style="width: 100%; max-width: 600px;"
          />
        </div>
        <span v-else class="detail">No crop data available</span>
      </div>
    </div>

    <div class="p-col-12 p-xl-6">
      <div class="card summary">
        <span class="title">Bin % Allocation</span>
        <div v-if="unloadsPerBinPercentage" class="center">
          <Chart :options="chartOptionsPie"
            type="pie"
            :data="unloadsPerBinPercentage"
            style="width: 100%; max-width: 600px;"
          />
        </div>
        <span v-else class="detail">No bin data available</span>
      </div>
    </div>

    <div class="p-col-12 p-xl-6">
      <div class="card summary">
        <span class="title">Crop % Allocation</span>
        <div v-if="unloadsPerCropPercentage" class="center">
          <Chart :options="chartOptionsPie"
            type="pie"
            :data="unloadsPerCropPercentage"
            style="width: 100%; max-width: 600px;"
          />
        </div>
        <span v-else class="detail">No crop data available</span>
      </div>
    </div>
  </div>
  <div v-else>
    <ProgressSpinner />
  </div>
</template>

<script>
import DashboardService from "../service/dashboardService";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      chartOptionsBar: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            labels: {
              filter: (legendItem, data) => (typeof legendItem.text !== 'undefined') // else it shows undefined
            }
          },
          tooltip: {
            callbacks: {
              label: (object) => {
                let label = object.dataset.label || '';
                const value = object.parsed.y || 0;
                return `${label}: ${value.toLocaleString()} ${this.selectedDashboardUnit}`;
              }
            }
          },
        },
        scales: {
          y: {
            ticks: {
              callback: (value) => {
                return `${value.toLocaleString()} ${this.selectedDashboardUnit}`;
              }
            }
          }
        }
      },

      chartOptionsPie: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: (object) => {
                let label = object.label || ''
                const value = object.parsed || 0
                return `${label}: ${value.toLocaleString()}%`
              }
            }
          }
        }
      },
      loading: null,
      totalFieldsHarvested: null,
      totalCropsHarvested: null,
      totalUnloaded: null,
      carts: null,
      seasons: null,
      unloadsPerBin: null,
      unloadsPerCrop: null,
      unloadsPerBinPercentage: null,
      unloadsPerCropPercentage: null,
      filters: [],
      weightSelectOptions: [
        {
          label: "kg",
          command: () => {
            this.setUnit(0, 0);
          },
        },
        {
          label: "lbs",
          command: () => {
            this.setUnit(1, 0);
          },
        },
        {
          label: "T",
          command: () => {
            this.setUnit(2, 0);
          },
        },
        {
          label: "bu",
          command: () => {
            this.setUnit(0, 1);
          },
        },
        {
          label: "m³",
          command: () => {
            this.setUnit(1, 1);
          },
        },
      ],
    };
  },
  dashboardService: null,

  created() {
    this.dashboardService = new DashboardService();
  },
  mounted() {
    this.loading = true;
    this.loadFilterOptions();    
  },

  methods: {
    loadFilterOptions() {
      this.dashboardService.getFilterOptions().then((data) => {
        this.carts = data.carts;
        this.seasons = data.seasons;
        this.loadData();
      });
    },
    loadData() {
      this.filters = [];
      if (!this.selectedSeasons) {
        this.$store.commit("filter/setSelectedSeasons", this.seasons);
      }
      if (!this.selectedCarts) {
        this.$store.commit("filter/setSelectedCarts", this.carts);
      }
      let seasonValues = this.selectedSeasons.map(v => v.id).join(",");
      let cartValues = this.selectedCarts.map(v => v.id).join(",");
      this.filters.push({ key: "cartIds", value: cartValues });
      this.filters.push({ key: "seasonIds", value: seasonValues });
      this.dashboardService
        .getDashboard(this.filters)
        .then((data) => {
          this.totalFieldsHarvested = data.total_fields;
          this.totalCropsHarvested = data.total_crops;
          this.totalUnloaded = data.total_unload_weight;
          this.unloadsPerBin = data.total_unload_per_bin;
          this.unloadsPerCrop = data.total_unload_per_crop;
          this.unloadsPerBinPercentage = data.percentage_unload_per_bin;
          this.unloadsPerCropPercentage = data.percentage_unload_per_crop;
          this.$store.commit("units/setUnits", data.units);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleUnit() {
      this.loading = true;
      this.dashboardService.toggleUnit().then(() => {
        this.loadData();
      });
    },
    setUnit(unit, type = 0) {
      this.loading = true;
      this.dashboardService.setUnit(unit, type).then(() => {
        this.loadData();
      });
    },
    onSelectSeasonsOrCarts() {
      this.loading = true;
      this.loadData();
    },
    getMultiSelectLabel(value, label) {
      if (value && value.length > 0) {
        return value.length + ' ' + label + (value.length > 1 ? 's' : '') + ' Selected'
      }
      return 'Select ' + label + '(s)'
    },
  },
  computed: {
    ...mapGetters("units", ["selectedDashboardUnit"]),
    selectedCarts: {
      get() {
        return this.$store.state.filter.selectedCarts;
      },
      set(value) {
        this.$store.commit("filter/setSelectedCarts", value);
      },
    },
    selectedSeasons: {
      get() {
        return this.$store.state.filter.selectedSeasons;
      },
      set(value) {
        this.$store.commit("filter/setSelectedSeasons", value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.p-multiselect-cart-option {
  display: inline-block;
  vertical-align: middle;

  span {
    margin-top: 0.125em;
  }
}
</style>