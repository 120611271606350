
import baseService from "./baseService";

export default class UnloadsService extends baseService {

    getUnloads(page = 1, sortString = '', filters = [], perPage = 10) {
        let url = 'api/unloads'

        return this.paged(url, page, sortString, filters, perPage);
    }

    export(sortString = '', filters = []){
        let url = 'api/unloads/xlsx'

        return this.download(url, sortString, filters);
    }

    getFilters(){
        let url = 'api/unloads/filters'

        return this.get(url);
    }

    getTruckloads(truckId, page = 1, perPage = 10, seasonId=null) {
        let url = 'api/unloads/truckloads/' + truckId + '/season/'
        if(seasonId){
            url += seasonId
        }

        return this.paged(url, page, null, null, perPage);
    }

    getTruckloadsForDropdown(truckId, seasonId) {
        let url = 'api/unloads/truckloads/dropdown/' + truckId + '/season/' + seasonId
        return this.get(url);
    }

    getTruckloadFilters(){
        let url = 'api/unloads/truckloads/filters'

        return this.get(url);
    }

    save(obj) {
        let url = 'api/unloads/save/'
        return super.save(url, obj);
    }

    delete(obj) {
        let url = 'api/unloads/' + obj.id
        return super.delete(url);
    }

    getLatestUnload(){
        let url = 'api/unloads/latestUnload/'
        return this.get(url);
    }
}