export default {
    namespaced: true,
    state: {
        selectedSeason: "",
        selectedSeasons: "",
        selectedCarts: "",
    },
    getters: {
        selectedSeason: state => state.selectedSeason,
        selectedSeasons: state => state.selectedSeasons,
        selectedCarts: state => state.selectedCarts,
    },
    mutations: {
        setSelectedSeason: (state, selectedSeason) => state.selectedSeason = selectedSeason,
        setSelectedSeasons: (state, selectedSeasons) => state.selectedSeasons = selectedSeasons,
        setSelectedCarts: (state, selectedCarts) => state.selectedCarts = selectedCarts,
    },
    actions: {}
}
