import Vue from 'vue';
import App from './App.vue';
import router from './router';
import moment from 'moment'
import './primeVue';
import store from "./store";
import './thirdPartyImports';
import './assets/layout/layout.scss';
import numeral from 'numeral';
import {dom} from '@fortawesome/fontawesome-svg-core'
import axios from "axios";
import {LMap, LTileLayer, LMarker, LControl, LPolygon, LCircle} from 'vue2-leaflet';
import LDrawToolbar from 'vue2-leaflet-draw-toolbar';
import 'leaflet/dist/leaflet.css';
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-toolbar/dist/leaflet.toolbar.css";

Vue.component('LMap', LMap);
Vue.component('LTileLayer', LTileLayer);
Vue.component('LMarker', LMarker);
Vue.component('LControl', LControl);
Vue.component('LPolygon', LPolygon);
Vue.component('LDrawToolbar', LDrawToolbar);
Vue.component('LCircle', LCircle);
dom.watch()

Vue.config.productionTip = false;

Vue.filter('date', function (value) {
    if (value) {
        return moment(String(value)).local(true).format('DD MMM YYYY')
    }
})

Vue.filter('datetime', function (value) {
    if (value) {
        return moment(String(value)).local(true).format('DD MMM YYYY h:mm:ss A')
    }
})

Vue.filter('datetime1', function (value) {
    if (value) {
        return moment(String(value)).local().format('DD MMM YYYY, H:mm A')
    }
})

Vue.filter('serverDatetime', function (value) {
    if (value) {
        return moment(String(value)).utc().format("YYYY-MM-DD HH:mm:ssZ");
    }
})

Vue.filter('weightFilter', function (value) {
    if (value) {
        switch (store.getters['units/units'].weight_unit.id) {
            case 0:
                return numeral(value).format(0, 0)
            case 1:
                return numeral(value).format(0, 0.0)
            case 2:
                return numeral(value).format(0, 0.000)
        }
    }
    return 0
})

Vue.filter('selectedDashboardUnitFilter', function (value) {
    if (value) {
        switch (store.getters['units/selectedDashboardUnit']) {
            case 'kg':
                return numeral(value).format("0,0")
            case 'lbs':
                return numeral(value).format("0,0.0")
            case 'T':
                return numeral(value).format("0,0.000")
            case 'bu':
                return numeral(value).format("0,0.0")
            case 'm³':
                return numeral(value).format("0,0.000")
        }
    }
    return 0
})

Vue.filter('volumeFilter', function (value) {
    if (value) {
        switch (store.getters['units/units'].volume_unit.description) {
            case 'bu':
            default:
                return numeral(value).format("0,0.0")
            case 'm³':
                return numeral(value).format("0,0.000")
        }
    }
    return 0
})

Vue.filter('densityFilter', function (value) {
    if (value) {
        return numeral(value).format('0, 0.0')
    }
    return 0
})

Vue.filter('temperatureFilter', function (value) {
    if (value) {
        return numeral(value).format('0, 0.0')
    }
    return 0
})

Vue.filter('moistureFilter', function (value) {
    if (value) {
        return numeral(value).format('0, 0.0')
    }
    return 0
})

Vue.filter('yieldFilter', function (value) {
    if (value) {
        return numeral(value).format('0, 0.0')
    }
    return 0
})

Vue.filter('areaFilter', function (value) {
    if (value) {
        return numeral(value).format('0, 0.0')
    }
    return 0
})

axios.interceptors.response.use(null,
    error => {
        if (401 === error.response.status) {
            router.push('/logout')
        }
        return Promise.reject(error);
    }
)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
