<template>
  <div style="max-height: 100vh">
    <div v-if="!register_login">
      <Menubar :model="menu" >
        <template #start>
          <router-link to="/">
            <img alt="logo" src="./assets/layout/images/cloud-logo.svg" height="45px">
          </router-link>
        </template>
        <template #end>
          <div style="text-align: center; display:table;">

            <Button class="p-button-menubar-user-profile" @click="toggleProfileMenu">
              <i class="pi pi-user" style="font-size: 24px"/>
              <span style="display:table-cell; vertical-align:middle; margin: 0 5px">{{ user.name }}</span>
              <i class="pi pi-chevron-down"/>
            </Button>

            <Menu ref="menu" :model="profileMenu" :popup="true"/>
          </div>
        </template>
      </Menubar>
      <div class="layout-main" style="overflow-y: auto;overflow-x: hidden;  max-height: 90vh">
        <Toast position="bottom-right"/>
        <router-view style="margin-bottom: 5em; min-height: 80vh"/>
      </div>

    </div>
    <div v-else>
      <Toast/>
      <div style="overflow-x: hidden;">
        <router-view/>
      </div>
    </div>
    <ConfirmDialog/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      register_login: false,
      menu: [
        {
          label: 'Farm Objects', icon: 'fas fa-tractor',
          items: [
            {
              label: 'Field Management',
              icon: 'fas fa-fw fa-grip-horizontal',
              to: '/farm-objects/farm-management'
            },
            {
              label: 'Truck Management',
              icon: 'fas fa-fw fa-truck',
              to: '/farm-objects/truck-management'
            },
            // {
            //   label: 'Combine Management',
            //   icon: 'fas fa-fw fa-snowplow',
            //   to: '/farm-objects/combine-management'
            // },
            {
              label: 'Bin Management',
              icon: 'fas fa-fw fa-database',
              to: '/farm-objects/bin-management'
            },
            {
              label: 'Crop Management',
              icon: 'fas fa-fw fa-wheat',
              to: '/farm-objects/crop-management'
            },
            {
              label: 'Merge Tool',
              icon: 'fas fa-object-group',
              to: '/farm-objects/merge-tool'
            },

          ]
        },
        {label: 'Unloads', icon: 'fas fa-fw fa-truck-loading', to: '/unloads'},
        {
          label: 'Reports', icon: 'fas fa-fw fa-clipboard-list', items: [
            {
              label: 'Unload Summary',
              icon: 'fas fa-fw fa-chart-pie',
              to: '/reports/unloads-summary-report'
            },
            {
              label: 'Unload Totals',
              icon: 'fas fa-fw fa-chart-bar',
              to: '/reports/unloads-total-report'
            },
            {
              label: 'Unload Details',
              icon: 'fas fa-fw fa-clipboard-list',
              to: '/reports/unloads-detail-report'
            },
            {
              label: 'Bin Allocation',
              icon: 'fas fa-fw fa-database',
              to: '/reports/bin-allocation-report'
            },
          ]
        },
        {
          label: 'Imports', icon: 'fas fa-fw fas fa-exchange-alt', items: [
            {
              label: 'John Deere',
              icon: 'fas fa-john-deere',
              to: '/integrations/john-deere'
            },
            {
              label: 'CSV Import',
              icon: 'fas fa-fw fa-file-csv',
              to: '/integrations/csv'
            },
            {
              label: 'Agrimatics Import',
              icon: 'fas fa-fw fa-upload',
              to: '/integrations/agrimatics'
            }
          ]
        },
        {label: 'Seasons', icon: 'fas fa-fw fa-cloud-sun', to: '/season-management'},
        {label: 'Cart Settings', icon: 'fas fa-fw fa-sliders-h', to: '/cart-settings'},

      ],
      profileMenu: [
        {
          label: 'Account Settings',
          icon: 'pi pi-fw pi-cog',
          to: '/account-settings'
        },
        {
          label: 'Logout',
          icon: 'pi pi-fw pi-power-off',
          to: '/logout'
        },

      ]
    }
  },
  watch: {
    $route() {
      switch (this.$route.name) {
        case 'register':
        case 'login':
        case 'forgot-password':
        case 'reset-password':
        case 'confirm-email':
          this.register_login = true
          break;
        default:
          this.register_login = false
      }
      this.menuActive = false
      this.$toast.removeAllGroups()
    }
  },
  methods: {
    toggleProfileMenu(event) {
      this.$refs.menu.toggle(event);
    }
  },
  computed: {
    ...mapGetters('user', [
      'user'
    ]),
  }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
