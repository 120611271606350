import Vue from 'vue'
import Vuex from 'vuex'
import user from "@/store/user";
import userSortStore from "@/store/userSortStore";
import createPersistedState from "vuex-persistedstate";
import units from "@/store/units";
import lookups from "@/store/lookups";
import filter from "@/store/filter";

Vue.use(Vuex)

const store = new Vuex.Store({
        namespace: true,
        modules: {
            user, userSortStore, units, lookups, filter
        },
        plugins: [createPersistedState()],
    }
)

export default store