import Vue from 'vue';
import Router from 'vue-router';
import Dashboard from './components/Dashboard.vue';
import authService from "./service/authService";

Vue.use(Router);

const router = new Router({
    mode: "history",
    routes: [
        {
            path: '/',
            name: 'dashboard',
            meta: {requiresAuth: true},
            component: Dashboard
        },
        {
            path: '/register',
            name: 'register',
            meta: {requiresAuth: false},
            component: () => import('./components/Register.vue')
        },
        {
            path: '/login',
            name: 'login',
            meta: {requiresAuth: false},
            component: () => import('./components/Login.vue')
        },
        {
            path: '/confirm-email',
            name: 'confirm-email',
            meta: {requiresAuth: false},
            component: () => import('./components/ConfirmEmail.vue')
        },
        {
            path: '/logout',
            name: 'logout',
            meta: {requiresAuth: true},
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: () => import('./components/ForgotPassword.vue'),
            meta: {requiresAuth: false},
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: () => import('./components/ResetPassword.vue'),
            meta: {requiresAuth: false},
        },
        //Farm Objects
        {
            path: '/farm-objects/farm-management',
            name: 'farm-management',
            meta: {requiresAuth: true},
            component: () => import('./components/FieldManagement.vue')
        },
        {
            path: '/farm-objects/truck-management',
            name: 'truck-management',
            meta: {requiresAuth: true},
            component: () => import('./components/TruckManagement.vue')
        },
        {
            path: '/farm-objects/combine-management',
            name: 'combine-management',
            meta: {requiresAuth: true},
            component: () => import('./components/CombineManagement.vue')
        },
        {
            path: '/farm-objects/bin-management',
            name: 'bin-management',
            meta: {requiresAuth: true},
            component: () => import('./components/BinManagement.vue')
        },
        {
            path: '/binTransfers/:binId',
            name: 'binTransfers',
            meta: {requiresAuth: true},
            component: () => import('./components/BinTransfers.vue')
        },
        {
            path: '/farm-objects/crop-management',
            name: 'crop-management',
            meta: {requiresAuth: true},
            component: () => import('./components/CropManagement.vue')
        },
        {
            path: '/unloads',
            name: 'unloads',
            meta: {requiresAuth: true},
            component: () => import('./components/Unloads.vue')
        },
        {
            path: '/truckloads/:truckId',
            name: 'truckloads',
            meta: {requiresAuth: true},
            component: () => import('./components/Truckload.vue')
        },
        {
            path: '/reports/unloads-total-report',
            name: 'unloads-total-report',
            meta: {requiresAuth: true},
            component: () => import('./components/UnloadTotalsReport.vue')
        },
        {
            path: '/reports/unloads-detail-report',
            name: 'unloads-detail-report',
            meta: {requiresAuth: true},
            component: () => import('./components/UnloadDetailsReport.vue')
        },
        {
            path: '/reports/unloads-summary-report',
            name: 'unloads-summary-report',
            meta: {requiresAuth: true},
            component: () => import('./components/UnloadSummaryReport.vue')
        },
        {
            path: '/reports/bin-allocation-report',
            name: 'bin-allocation-report',
            meta: {requiresAuth: true},
            component: () => import('./components/BinAllocationReport.vue')
        },
        //Integrations
        {
            path: '/integrations/john-deere',
            name: 'john-deere',
            meta: {requiresAuth: true},
            component: () => import('./components/JohnDeereIntegration.vue')
        },
        {
            path: '/integrations/csv',
            name: 'csv-import',
            meta: {requiresAuth: true},
            component: () => import('./components/CSVImportIntegration.vue')
        },
        {
            path: '/integrations/agrimatics',
            name: 'agrimatics-import',
            meta: {requiresAuth: true},
            component: () => import('./components/AgrimaticsIntegration.vue')
        },
        {
            path: '/season-management',
            name: 'season-management',
            meta: {requiresAuth: true},
            component: () => import('./components/SeasonManagement')
        },
        {
            path: '/account-settings',
            name: 'account-settings',
            meta: {requiresAuth: true},
            component: () => import('./components/AccountSettings.vue')
        },
        {
            path: '/cart-settings',
            name: 'cart-settings',
            meta: {requiresAuth: true},
            component: () => import('./components/CartSettings.vue')
        },
        {
            path: '/farm-objects/merge-tool',
            name: 'merge-tool',
            meta: {requiresAuth: true},
            component: () => import('./components/MergeTool.vue')
        },
    ],
    scrollBehavior() {
        return {x: 0, y: 0};
    }
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    try {
        if (requiresAuth && to.path === '/logout') {
            authService.logout()
            next('/login')
        } else {
            if (requiresAuth && !authService.loggedIn()) {
                next('login')
            } else {
                next()
            }
        }
    } catch
        (e) {
        console.log(e)
        next()
    }
})

export default router;