export default class Units {
    constructor() {
        this.unit_of_measurement_type = {}
        this.weight_unit = {}
        this.volume_unit = {}
        this.temperature_unit = {}
        this.area_unit = {}
        this.density_unit = {}
    }

    static mutations() {
        return {
            setUnits: (state, units) => state.units = units
        }
    }
}
