export default class User {
    constructor() {
        this.name = ''
        this.email = ''
        this.user_profile_id = ''
    }

    static mutations() {
        return {
            setUser: (state, user) => state.user = user
        }
    }
}
