import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import iFrameResize from 'iframe-resizer/js/iframeResizer'
import {library, dom} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {faJohnDeere} from './assets/icon/faJohnDeere'
import {faWheat} from './assets/icon/faWheat'
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

Vue.use(VueLodash, {lodash: lodash})
Vue.use(vueNumeralFilterInstaller);
Vue.use(VueAxios, axios)

Vue.directive('resize', {
    bind: function (el, {value = {}}) {
        el.addEventListener('load', () => iFrameResize(value, el))
    }
})

library.add(fas, faJohnDeere)
library.add(fas, faWheat)

dom.watch()

Vue.component('font-awesome-icon', FontAwesomeIcon)