import baseService from "./baseService";

export default class DashboardService extends baseService {

    getFilterOptions() {
        let url = 'api/dashboard/filterOptions'

        return this.get(url);
    }

    getDashboard(filters) {
        let url = 'api/dashboard'

        return this.getFiltered(url, filters);
    }

    toggleUnit() {
        let url = 'api/dashboard/toggle/unit'

        return this.post(url);
    }

    setUnit(unit, type=0) {
        let url = `api/dashboard/set-units/${unit}/${type}`
        return this.post(url);
    }

}