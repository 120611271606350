import User from "@/models/user";

export default {
    namespaced: true,

    state: {
        user: new User,
        viewedAgrimaticsInstructions: false,
    },
    getters: {
        user: state => state.user,
        viewedAgrimaticsInstructions: state => state.viewedAgrimaticsInstructions,
    },
    mutations: {
        ...User.mutations(),
        setViewedAgrimaticsInstructions: (state, viewedAgrimaticsInstructions) => state.viewedAgrimaticsInstructions = viewedAgrimaticsInstructions,
    },
    actions: {}
}


