import UnloadsService from '../service/unloadService'

export default {
    namespaced: true,

    state: {
        crops: [],
        fields: [],
        farms: [],
        clients: [],
        trucks: [],
        bins: [],
        carts: [],
        seasons: [],
    },
    getters: {
        crops: state => state.crops,
        fields: state => state.fields,
        farms: state => state.farms,
        clients: state => state.clients,
        trucks: state => state.trucks,
        bins: state => state.bins,
        carts: state => state.carts,
        seasons: state => state.seasons,
    },
    mutations: {
        setCrops: (state, crops) => state.crops = crops,
        setFields: (state, fields) => state.fields = fields,
        setFarms: (state, farms) => state.farms = farms,
        setClients: (state, clients) => state.clients = clients,
        setTrucks: (state, trucks) => state.trucks = trucks,
        setBins: (state, bins) => state.bins = bins,
        setCarts: (state, carts) => state.carts = carts,
        setSeasons: (state, seasons) => state.seasons = seasons,
    },
    actions: {
        loadAll({commit}) {
            new UnloadsService().getFilters().then(data => {
                commit("setCrops", data.crops)
                commit("setFields", data.fields)
                commit("setFarms", data.farms)
                commit("setClients", data.clients)
                commit("setTrucks", data.trucks)
                commit("setBins", data.bins)
                commit("setCarts", data.carts)
                commit("setSeasons", data.seasons)
            })
        }
    },
}