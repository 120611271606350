import axios from 'axios'

const baseUrl = process.env.VUE_APP_HAULMASTER_BASE_URL;
export default class BaseService {

    get(url) {
        return axios.get(baseUrl + url, this.getAuthHeader()).then(res => res ? res.data : null);
    }

    post(url, data = {}) {
        return axios.post(baseUrl + url, data,  this.getAuthHeader()).then(res => res ? res.data : null);
    }

    save(url, obj) {
        return axios.post(baseUrl + url, obj.toJson(), this.getAuthHeader()).then(res => res ? res.data : null);
    }

    delete(url) {
        return axios.delete(baseUrl + url, this.getAuthHeader()).then(res => res.data);
    }

    getFiltered(url, filters) {

        let operator = '?';

        url = this.filteredUrl(filters, url, operator);

        return axios.get(url, this.getAuthHeader()).then(res => res.data);
    }

    filteredUrl(filters, url, operator) {
        if (filters && filters.length > 0) {
            filters.forEach(function (filter) {
                url += operator + filter.key + '=' + filter.value
                operator = '&'
            })
        }
        return baseUrl + url;
    }

    paged(url, page = 1, sortString = '', filters = [], perPage = 10) {
        let operator = '?'
        if (page > 0) {
            url += operator + 'page=' + page
            operator = '&'
        }

        // # of rows
        url += operator + 'perPage=' + perPage

        if (sortString && sortString.length > 0) {
            url += operator + 'sort=' + sortString
            operator = '&'
        }

        url = this.filteredUrl(filters, url, operator);

        return axios.get(url, this.getAuthHeader()).then(res => res.data);
    }

    download(url, sortString = '', filters = []) {
        let operator = '?'

        if (sortString && sortString.length > 0) {
            url += operator + 'sort=' + sortString
            operator = '&'
        }
        url = this.filteredUrl(filters, url, operator);

        let config = this.getAuthHeader();
        config['responseType'] = 'blob'
        return axios.get(url, config).then(res => res.data);
    }

    token() {
        return localStorage.getItem('_token')
    }

    getBearerToken() {
        return 'Bearer ' + this.token()
    }

    getAuthHeader() {
        return {
            'headers': {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': (this.token()) ? this.getBearerToken() : null
            }
        }
    }

}