import Units from "@/models/units";

export default {
    namespaced: true,

    state: {
        user: new Units()
    },
    getters: {
        units: state => state.units,
        selectedDashboardUnit: state => {
            if (state.units.unit_of_measurement_type != null && state.units.unit_of_measurement_type.id === 0){
                return state.units.weight_unit.description
            } else if (state.units.unit_of_measurement_type != null && state.units.unit_of_measurement_type.id === 1){
                return state.units.volume_unit.description
            }
            return 'kg'
        }
    },
    mutations: {
        ...Units.mutations()
    },
    actions: {},

}