import axios from "axios";
import store from "../store"


const baseUrl = process.env.VUE_APP_HAULMASTER_BASE_URL;
const authService = {
    loggedIn() {
        return !!(localStorage.getItem('_token'))
    },
    setToken(token) {
        localStorage.setItem('_token', token)
    },
    setUserProfile(userProfile) {
        localStorage.setItem('_userProfile', userProfile)
    },
    login(login) {
        return axios.post(baseUrl + 'api/auth/login', login.toJson(), this.getHeader())
            .then(res => {
                this.setToken(res.data.api_token)
                this.setUserProfile(res.data.user.user_profile_id)
                store.commit('units/setUnits', res.data.units)
                store.commit('user/setUser', res.data.user)
            })
    },
    forgotPassword(emailAddress) {
        return axios.post(baseUrl + 'password/email', {email: emailAddress}, this.getHeader()).then(res => res.data)
    },
    resetPassword(reset) {
        return axios.post(baseUrl + 'password/reset', reset, this.getHeader()).then(res => res.data)
    },
    logout() {
        localStorage.removeItem('_token')
        localStorage.removeItem('_userProfile')
        localStorage.removeItem('vuex')
    },
    getHeader() {
        return {
            'headers': {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
        }
    }
}

export default authService
